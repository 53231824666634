import React from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Flex, Spacer } from '@chakra-ui/react';
import { useConfig } from './context/useConfig';
import NavHeader from './components/NavHeader';
import HomePage from './components/HomePage';
import ShopHome from './components/Shop/Home';
import Footer from './components/Footer';
import './App.scss';

function App() {
  const config = useConfig();
  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>{config?.domain?.domTitle}</title>
      </Helmet>
      <Flex textAlign='center' minH='100vh' direction='column'>
        <Router>
          <NavHeader />
          <Switch>
            <Route path='/shop'>
              <ShopHome />
            </Route>
            <Route path=''>
              <HomePage />
            </Route>
          </Switch>
        </Router>
        <Spacer />
        <Footer />
      </Flex>
    </>
  );
}

export default App;
