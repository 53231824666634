import React from 'react';
import { Box, /*Heading, */ HStack, Button, Link, VStack, Text, Image } from '@chakra-ui/react';
import { MAX_PAGE_WIDTH, PLANTLIFE_GREEN, PLANTLIFE_GREEN_DARK } from '../constants';
import { useConfig } from 'context/useConfig';
import { useHistory } from 'react-router-dom';

// eslint-disable-next-line no-lone-blocks
{
  /* <Heading size='xl' fontWeight='200'>
  Welcome to
</Heading>
<Heading size='2xl' fontWeight='900'>
  Plantlife Cannabis
  <br />
  {config?.domain?.storeNameLocationOnly}
</Heading> */
}

const HomePage = () => {
  const config = useConfig();
  const history = useHistory();
  return (
    <Box maxW={MAX_PAGE_WIDTH} mx='auto' py='1.3em'>
      <VStack>
        <Button
          onClick={() => history.push('/shop')}
          size='lg'
          backgroundColor={PLANTLIFE_GREEN}
          color='white'
          fontWeight='400'
          fontSize='2xl'
          _hover={{ backgroundColor: PLANTLIFE_GREEN_DARK, boxShadow: '0 0 12px #BBFF69' }}
        >
          Shop{' '}
          <Text as='span' fontWeight='900' mx='0.5em' color='#BBFF69'>
            {config?.domain?.storeNameLocationOnly}
          </Text>{' '}
          Delivery or Pickup
        </Button>
        <Box m='2em'>
          <Image
            src='/public/gfx/delivery-webslider-2-march2022.jpg'
            display='block'
            m='1em 0 2em'
            borderRadius='8px'
            boxShadow='0 0 10px rgba(0,0,0,0.6)'
          />
        </Box>
        <HStack spacing='4em'>
          <Link href='https://cannabissense.ca/' isExternal _focus={{ outline: 'none' }}>
            <Image src='/public/gfx/cannabisSense_higherEducation.jpg' borderRadius='8px' />
          </Link>
          <Link
            href='https://cannabissense.ca/use-legally/safe-storage'
            isExternal
            _focus={{ outline: 'none' }}
          >
            <Image src='/public/gfx/cannabisSense_stashYourStash.jpg' borderRadius='8px' />
          </Link>
        </HStack>
      </VStack>
    </Box>
  );
};

export default HomePage;
