import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import styled from '@emotion/styled';
import { Box, Flex, Heading, HStack, Image, Link, Spacer /*, Text*/ } from '@chakra-ui/react';
import { MAX_PAGE_WIDTH, PLANTLIFE_GREEN } from '../constants';
import { useConfig } from 'context/useConfig';

const HeaderBox = styled(Box)`
  padding: 24px 0;
  margin-bottom: 8px;
  background-color: ${PLANTLIFE_GREEN};
  border-bottom: 1px solid #555;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2), 0 4px 4px rgba(255, 255, 255, 1);
`;

const NavHeader = () => {
  const config = useConfig();
  return (
    <HeaderBox w='100%' bg='#3E8B30' py='25px' zIndex='2'>
      <Flex maxW={MAX_PAGE_WIDTH} mx='auto'>
        <Box mr='1em'>
          <Link as={RouterLink} to='/' _focus={{ outline: 'none' }}>
            <Image src='/public/gfx/plantifelogo-white.png' w='auto' h={['32px', '50px']} />
          </Link>
        </Box>
        <Heading size='xl' color='white' fontWeight='200'>
          {config?.domain?.storeNameLocationOnly}
        </Heading>
        <Spacer />
        <HStack spacing='24px'>
          {/*
          <Link
            as={RouterLink}
            to='/shop'
            color='white'
            textTransform='uppercase'
            fontWeight='bold'
            _focus={{ outline: 'none' }}
          >
            <Text as='span' fontSize='xl'>
              Shop Delivery or Pickup
            </Text>
          </Link>
          */}
        </HStack>
      </Flex>
    </HeaderBox>
  );
};

export default NavHeader;
