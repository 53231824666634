import React from 'react';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { ConfigProvider } from './context/useConfig';
import App from 'App';

// import { ColorModeSwitcher } from './ColorModeSwitcher';
// import { Logo } from './Logo';

// <ColorModeSwitcher justifySelf="flex-end" />

const AppContainer = () => {
  const xTheme = extendTheme({
    fonts: {
      body: 'Poppins, sans-serif',
      heading: 'Poppins, sans-serif'
    }
  });
  return (
    <ChakraProvider theme={xTheme}>
      <ConfigProvider>
        <App />
      </ConfigProvider>
    </ChakraProvider>
  );
};

export default AppContainer;
