import React, { useEffect } from 'react';
import { Box /*, Heading, Text*/ } from '@chakra-ui/react';
// import { MAX_PAGE_WIDTH } from '../../constants';
import { useConfig } from 'context/useConfig';

const DUTCHIE_ATTACH_ID = 'dutchie-attach-point';
const AGE_GATE_ATTACH_ID = 'plc-age-gate-check';
const AGE_GATE_PORTAL_ID = 'plc-age-gate-portal';
const AGE_GATE_BUILD_ID = '97a7cb19';

// localhost:
const LOCAL_AGE_GATE = `http://localhost:34800/static/js/main.${AGE_GATE_BUILD_ID}.js`;
// prod:
const PROD_AGE_GATE = `https://plc-age-gate-takeover.dynamicintegratedtechnology.com/static/js/main.${AGE_GATE_BUILD_ID}.js`;

// <script async="" id="dutchie--embed__script" src="https://dutchie.com/api/v2/embedded-menu/620d82cf76b7f700948d63f8.js?chainLocations=true&searchMethod=deliveryArea"></script>
// const DUTCHIE_DELIVERY_EMBED =
//   'https://dutchie.com/api/v2/embedded-menu/620d82cf76b7f700948d63f8.js?chainLocations=true&searchMethod=deliveryArea';

// getting from context now:
// const DUTCHIE_TERRA_LOSA = 'https://dutchie.com/api/v2/embedded-menu/5fcffe750db90800b780cbdb.js';

const ShopHome = () => {
  const config = useConfig();

  useEffect(() => {
    const dutchiePoint = document.getElementById(DUTCHIE_ATTACH_ID);

    const dutchieScript = document.createElement('script');
    dutchieScript.src = config?.domain?.dutchieIframeUrl;
    dutchieScript.id = 'dutchie--embed__script';
    dutchieScript.async = true;
    dutchieScript.type = 'text/javascript';
    dutchiePoint.appendChild(dutchieScript);

    const ageGateScript = document.createElement('script');
    ageGateScript.src =
      window.location.host.indexOf('localhost') === -1 ? PROD_AGE_GATE : LOCAL_AGE_GATE;
    ageGateScript.async = true;
    document.body.appendChild(ageGateScript);

    return () => {
      dutchiePoint.removeChild(dutchieScript);
      document.body.removeChild(ageGateScript);
      const ageGateDom = document.getElementById(AGE_GATE_PORTAL_ID);
      if (ageGateDom) {
        while (ageGateDom.firstChild) {
          ageGateDom.removeChild(ageGateDom.firstChild);
        }
      }
    };
  }, [config?.domain?.dutchieIframeUrl]);

  return (
    <Box h='calc(90vh-100)'>
      <div id={AGE_GATE_ATTACH_ID}></div>
      {/*
      <Heading size='3xl' fontWeight='500' fontStyle='italic'>
        Shop: Delivery or Pickup!
      </Heading>
      <Text fontSize='xs'>(age gate will protect here)</Text>
      */}
      <div id={DUTCHIE_ATTACH_ID} />
      {/*
      <iframe
        className='dutchie--iframe'
        id='dutchie--embed__iframe'
        frameBorder='0'
        title='Dutchie Menu'
        src='https://dutchie.com/embedded-menu/plantlife-cannabis-spruce-grove/locations?'
        allow='camera;microphone;geolocation;gyroscope'
        style={{ width: '100%', minWidth: '100%', border: 'none', height: '1008px' }}
      ></iframe>
      */}
    </Box>
  );
};

export default ShopHome;

/*

      <iframe
        className='dutchie--iframe'
        id='dutchie--embed__iframe'
        frameBorder='0'
        title='Dutchie Menu'
        src='https://dutchie.com/embedded-menu/plantlife-cannabis-spruce-grove/locations?'
        allow='camera;microphone;geolocation;gyroscope'
        style={{ width: '100%', minWidth: '100%', border: 'none', height: '1008px' }}
      ></iframe>
*/
