import React from 'react';
import { ImFacebook, ImTwitter } from 'react-icons/im';
import { FaInstagram } from 'react-icons/fa';
import styled from '@emotion/styled';
import { Box, Button, Flex, Heading, Stack, Link, Spacer } from '@chakra-ui/react';
import { MAX_PAGE_WIDTH, PLANTLIFE_GREEN as ICON_COLOR } from '../constants';

const AglcSearchButton = styled(Button)`
  background-color: ${ICON_COLOR};
  padding: 12px 16px;
  color: white;
  font-weight: 500;
  font-size: 0.8em;
  text-transform: uppercase;
  transition: background-color 0.35s ease, color 0.35s ease;
  border: 1px solid transparent;
  &:hover {
    background-color: #fff;
    color: ${ICON_COLOR};
    border: 1px solid ${ICON_COLOR};
  }
`;

const FooterBox = styled(Box)`
  margin-top: 20px;
  padding: 20px 0;
  background-color: #eee;
  border-top: 1px solid #aaa;
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.1);
`;

const iconHoverProps = {
  color: '#333',
  transform: 'scale(1.2)'
};

const Footer = () => {
  return (
    <FooterBox>
      <Flex maxW={MAX_PAGE_WIDTH} mx='auto' direction='row'>
        <Heading size='md' fontWeight='500' color={ICON_COLOR}>
          &copy; Plantlife Cannabis {new Date().getFullYear()}
        </Heading>
        <Spacer />
        <Stack spacing='24px' align='end'>
          <Stack spacing='36px' direction='row'>
            <Link href='https://www.facebook.com/plantlifecanada' isExternal>
              <Box color={ICON_COLOR} _hover={iconHoverProps}>
                <ImFacebook color='currentColor' />
              </Box>
            </Link>
            <Link href='https://instagram.com/plcannabis' isExternal>
              <Box color={ICON_COLOR} _hover={iconHoverProps}>
                <FaInstagram color='currentColor' />
              </Box>
            </Link>
            <Link href='https://twitter.com/plantlifecanada' isExternal>
              <Box color={ICON_COLOR} _hover={iconHoverProps}>
                <ImTwitter color='currentColor' />
              </Box>
            </Link>
          </Stack>
          <AglcSearchButton
            as={Link}
            href='https://aglc.ca/cannabis/retail-cannabis/cannabis-licensee-search'
            isExternal
            _focus={{ outline: 'none' }}
          >
            AGLC Cannabis Licensee Search
          </AglcSearchButton>
        </Stack>
      </Flex>
    </FooterBox>
  );
};

export default Footer;
