const DEFAULT_DELIVERY_DOMAIN = 'plantlifecannabisice.com';
const DOM_TITLE = 'Plantlife Cannabis {STORE_NAME} :: Shop Delivery or Pickup';
const DUTCHIE_IFRAME_URL = 'https://dutchie.com/api/v2/embedded-menu/{EMBED_CODE}.js';

const domainDictionary = {
  'plantlifecannabisice.com': {
    storeName: 'Plantlife Cannabis Ice District - Edmonton',
    storeNameLocationOnly: 'Ice District - Edmonton',
    dutchieEmbedIframeCode: '620d82cf76b7f700948d63f8'
  },
  'plantlifecannabisuni.com': {
    storeName: 'Plantlife Cannabis University - Calgary',
    storeNameLocationOnly: 'University - Calgary',
    dutchieEmbedIframeCode: '620d82cf76b7f700948d63f8'
  }

  // 'plantlifecannabisterralosa.com': {
  //   storeName: 'Plantlife Cannabis Terra Losa',
  //   storeNameLocationOnly: 'Terra Losa',
  //   dutchieEmbedIframeCode: '5fcffe750db90800b780cbdb'
  // },
  // 'plantlifecannabisharvestpointe.com': {
  //   storeName: 'Plantlife Cannabis Harvest Pointe',
  //   storeNameLocationOnly: 'Harvest Pointe',
  //   dutchieEmbedIframeCode: '607a102b18256400aeca50fc'
  // },
  // 'plantlifecannabiscochrane.com': {
  //   storeName: 'Plantlife Cannabis Cochrane',
  //   storeNameLocationOnly: 'Cochrane',
  //   dutchieEmbedIframeCode: '5fda32b12964c300f86d378f'
  // },
  // 'plantlifecannabisfoothills.com': {
  //   storeName: 'Plantlife Cannabis Foothills',
  //   storeNameLocationOnly: 'Foothills',
  //   dutchieEmbedIframeCode: ''
  // },
  // 'plantlifecannabismahogany.com': {
  //   storeName: 'Plantlife Cannabis Mahogany',
  //   storeNameLocationOnly: 'Mahogany',
  //   dutchieEmbedIframeCode: '5fda20302fd35a00fa352c67'
  // },
  // 'plantlifecannabisfortmcmurray.com': {
  //   storeName: 'Plantlife Cannabis Fort McMurray',
  //   storeNameLocationOnly: 'Fort McMurray',
  //   dutchieEmbedIframeCode: '5ff260aef0f21400a8829732'
  // },
  // 'plantlifecannabiscamrose.com': {
  //   storeName: 'Plantlife Cannabis Camrose',
  //   storeNameLocationOnly: 'Camrose',
  //   dutchieEmbedIframeCode: '5fda1e0c89581500c99538bf'
  // },
  // 'plantlifecannabiswainwright.com': {
  //   storeName: 'Plantlife Cannabis Wainwright',
  //   storeNameLocationOnly: 'Wainwright',
  //   dutchieEmbedIframeCode: '5ff0e2b5d6f01200d0a122b0'
  // },
};

const domTitle = storeName => {
  return DOM_TITLE.replace('{STORE_NAME}', storeName);
};

const dutchieIframeUrl = embedCode => {
  return DUTCHIE_IFRAME_URL.replace('{EMBED_CODE}', embedCode);
};

const domainConfig = () => {
  const urlParams = window?.URLSearchParams && new URLSearchParams(window?.location?.search);
  const queryHostname = urlParams?.get('testDomain');
  if (domainDictionary[queryHostname]) {
    return domainDictionary[queryHostname];
  }
  // 2. check the actual hostname if no override found
  const host = window?.location?.host?.replace('www.', '');
  if (domainDictionary[host]) {
    return domainDictionary[host];
  }
  return domainDictionary[DEFAULT_DELIVERY_DOMAIN];
};

export const getDomainConfig = () => {
  const config = domainConfig();
  if (config) {
    config.domTitle = domTitle(config.storeName);
    config.dutchieIframeUrl = dutchieIframeUrl(config.dutchieEmbedIframeCode);
  }
  return config || {};
};
