import { useContext, createContext } from 'react';
import { getDomainConfig } from '../constants/domainConfig';

const ConfigContext = createContext();

export default ConfigContext;

const defaultConfigObject = () => {
  return { domain: getDomainConfig() };
};

export const ConfigProvider = ({ children, value = defaultConfigObject() }) => {
  return <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>;
};

export const useConfig = () => {
  const config = useContext(ConfigContext);
  return config || defaultConfigObject();
};
